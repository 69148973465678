import * as React from "react"
import styled from "styled-components"
import { FC, useContext, useState } from "react"
import { SuppliesOrderContext } from "../../context/SuppliesOrderProvider"
import PaymentList from "../orders/PaymentList"
import { downloadReport, generateReportData } from "../../api/platform/reportApi"
import {
  LoginContext,
  Spinner,
  TopAlignedTextInput,
} from "@social-supermarket/social-supermarket-components"
import { PrimaryButton } from "@social-supermarket/social-supermarket-components"
import { PaymentType } from "@social-supermarket/social-supermarket-model"

const Container = styled.div`
  max-width: 1000px;
  margin: auto;
  padding-top: 30px;
`
const Actions = styled.div`
  padding: 20px 0 20px 0;
`
const NameInput = styled(TopAlignedTextInput)`
  width: 100%;
  margin-bottom: 30px;

  input {
    width: 300px;
  }
`
const PaymentListContainer = styled.div``

interface Props {}

const ImpactPage: FC<Props> = () => {
  const { token } = useContext(LoginContext)
  const suppliesContext = useContext(SuppliesOrderContext)
  const [selected, setSelected] = useState<PaymentType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [title, setTitle] = useState<string>("")

  const allOrders = [...suppliesContext.orders].filter(order =>
    ["processing", "pending_processing", "completed"].includes(order.status)
  )

  // const allOrders = suppliesContext.orders

  allOrders.sort((a, b) => (a.date > b.date ? -1 : b.date > a.date ? 1 : 0))

  console.log(selected)

  const handleDownloadReport = async () => {
    setIsLoading(true)
    const orderIds = selected.map(order => order.ID)
    await generateReportData(token, orderIds)
    await downloadReport(
      token,
      orderIds.join(","),
      title,
      "https://data.socialsupermarket.org/wp-content/uploads/2018/10/logo-Placeholder.jpeg",
      true
    )
    setIsLoading(false)
  }

  return (
    <Container>
      <Spinner isLoading={isLoading} label={"Downloading Report..."} />
      <h1>Impact Report Generator</h1>
      <p>
        This page allows you to generate a PDF impact report that shows the impact of your orders.
      </p>
      <ol>
        <li>Give your report a name, this will be used as the title of the report.</li>
        <li>Select the orders you would like to be included in your report.</li>
        <li>Click the "Generate Report" button and your PDF report will be downloaded.</li>
      </ol>
      <Actions>
        <NameInput
          name={"name"}
          onChange={event => setTitle(event.target.value)}
          value={title}
          label={"Report Name"}
          isValid={true}
          placeholder={"Our Impact Q1 2022"}
          description={
            "Give your report a name to describe the impact, eg the time period or the specific event the orders were for."
          }
        />
        <PrimaryButton onClick={handleDownloadReport} disabled={!selected.length}>
          Generate Report
        </PrimaryButton>
      </Actions>
      <PaymentListContainer>
        <Spinner isLoading={suppliesContext.isLoading} isLocal label={"Fetching your orders.."} />
        <PaymentList payments={allOrders} onRowSelectionChanged={setSelected} />
      </PaymentListContainer>
    </Container>
  )
}

export default ImpactPage
