import { api } from "@social-supermarket/social-supermarket-model"
import { getConfig } from "../../../config/index"
import { SelectionType } from "../../pages/admin/impact/AdminImpactPage"

export const generateReportData = async (token: string, orderIds: number[]): Promise<void> => {
  return api.platformGet(
    `${
      getConfig().baseUrl
    }/wp-json/social-supermarket/v1/reporting/generate-reporting-data?ids=${orderIds.join(",")}`,
    token
  )
}

export const generateAdhocReportData = async (
  token: string,
  selections: SelectionType[],
  title: string,
  includeDummyBenchmarking: boolean
): Promise<string> => {
  return api.platformPost(
    `${getConfig().baseUrl}/wp-json/social-supermarket/v1/reporting/generate-adhoc-report-data`,
    {
      selections,
      title,
      includeDummyBenchmarking,
    },
    token
  )
}

export const downloadReport = (
  token: string,
  orderIds: string,
  title: string,
  logoUrl: string,
  includeDummyBenchmarking: boolean
) => {
  const fileName = `${title}.pdf`

  return fetch(
    `${
      getConfig().baseUrl
    }/wp-json/social-supermarket/v1/reporting/download-report?ids=${orderIds}&title=${title}&logoUrl=${logoUrl}&includeDummyBenchmarking=${includeDummyBenchmarking}`,
    {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + token,
      }),
      credentials: "include",
    }
  )
    .then(response => response.blob())
    .then(blob => {
      var url = window.URL.createObjectURL(blob)
      var a = document.createElement("a")
      a.href = url
      a.download = fileName
      document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click()
      a.remove() //afterwards we remove the element again
    })
}

export const downloadReportFromJson = (
  token: string,
  userId: number,
  json: object,
  title: string,
  includeDummyBenchmarking: boolean
) => {
  const fileName = `${title}.pdf`

  return fetch(
    `${getConfig().baseUrl}/wp-json/social-supermarket/v1/reporting/download-report-from-json`,
    {
      method: "POST",
      body: JSON.stringify({
        userId,
        json,
        title,
        includeDummyBenchmarking,
      }),
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
      credentials: "include",
    }
  )
    .then(response => response.blob())
    .then(blob => {
      var url = window.URL.createObjectURL(blob)
      var a = document.createElement("a")
      a.href = url
      a.download = fileName
      document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click()
      a.remove() //afterwards we remove the element again
    })
}
