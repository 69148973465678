import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import Placeholder from "../../components/Placeholder"
import { colors } from "@social-supermarket/social-supermarket-model"

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 1px 1px 1px #ffffff;
  font-size: 1.2em;
  color: ${colors.darkBorderGrey};
`

interface Props {}

const DashboardPage: FC<Props> = () => {
  return <Placeholder label={"Coming Soon..."} />
}

export default DashboardPage
