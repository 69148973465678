import * as React from "react"
import { useContext } from "react"
import Layout from "../social-supermarket/components/Layout"
import { LoginContext } from "@social-supermarket/social-supermarket-components"
import DashboardPage from "../social-supermarket/pages/dashboard/DashboardPage"
import SEO from "../social-supermarket/components/Seo"
import ImpactPage from "../social-supermarket/pages/impact/ImpactPage"
import { getConfig } from "../config"

const Dashboard = () => {
  const loginContext = useContext(LoginContext)
  return loginContext.user ? (
    <Layout>
      <SEO title={"Impact Dashboard"} description={""} />
      {loginContext.loggedInUser.roles.includes("administrator") || !getConfig().isProd ? (
        <ImpactPage />
      ) : (
        <DashboardPage />
      )}
    </Layout>
  ) : (
    <Layout whiteBackground />
  )
}

export default Dashboard
