import * as React from "react"
import styled from "styled-components"
import { FC } from "react"

const Container = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 1px 1px 1px #ffffff;
  font-size: 1.2em;
  color: hsla(0, 0%, 0%, 0.7);
`

interface Props {
  label: string
}

const Placeholder: FC<Props> = ({ label }) => {
  return <Container>{label}</Container>
}

export default Placeholder
